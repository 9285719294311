<template>
  <div class="sectionn-recommend-game-mb">
    <SectionRecommentGame
      is-auto-play
      :is-hide-navigation="true"
      :slides-per-view="4"
      :space-between="8"
      is-loop
      class="recommend-game-content-custom"
    />
  </div>
</template>

<script setup lang="ts">
const SectionRecommentGame = defineAsyncComponent(() => import('@/components/common/recommended-game.vue'))
</script>
<style lang="scss" scoped src="assets/scss/components/mobile/pages/home/section-recommend/index.scss"></style>
